import backgroundImage from './../../assets/images/cy-bg-automated-subtitles.jpg';

export default theme => ({
    root: {
        height: '100vh',
        backgroundSize: 'none',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center top',
        position: 'relative'
    },
    boxVideo: {
        position: 'absolute',
        top: '100px',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        textAlign: 'center',
        height: '410px',
        width: '700px'
    }
})
