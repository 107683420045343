import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import Hls from "hls.js";

const VideoPlayer = ({
    classes,
    videoContent
}) => {

    useEffect(() => {
        try {
            var video = document.getElementById('video');
            var videoSrc = videoContent.url;
            if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);
                hls.subtitleDisplay = true;
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    video.play(); 
                });
            }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = videoSrc;
                video.addEventListener('loadedmetadata', function () {
                    video.play();
                });
            }
        } catch (error) {
            
        }
        
    }, [videoContent])



    return (
        <>
            <video
                className={classes.video}
                id="video"
                autoPlay
                controls></video>
        </>
    )
}

VideoPlayer.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(VideoPlayer)
