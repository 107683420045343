import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import VideoPlayer from './../../components/VideoPlayer'
import * as api from '../../utils/api';


const Home = ({
    classes
}) => {

    const  [videoContent, setVideoContent] = useState(null)

    useEffect(() => {
        const fetchVideo = async () => {
           try {
               const resp = await api.getVideo()
               setVideoContent(resp.data)
           } catch (error) {
               setVideoContent(null)
           }
        }
        fetchVideo()
        return () => {
            //
        }
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.boxVideo}>
                {videoContent ? (
                    <VideoPlayer videoContent={videoContent}/>
                ): null}
            </div>
       </div>
    )
}

Home.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Home)
