import React, { Suspense } from 'react';
import Main from './layouts/Main';
import Home from './screens/Home';
//import './mock/api';


function App() {
  return (
    <Suspense fallback={<h3>Loading....</h3>}>
            <Main>
              <Home />
            </Main>
    </Suspense>

    );
}

export default App;
